import React from 'react'
import Navbar from './Navbar'
import Desk from '../assets/Group 555.png';
import ConsultancyForm from './consultancyForm';
import Footer from './Footer';

const Consultancy = () => {
  
  return (
    <div className='flex flex-col'>
      <div className='z-[1000] relative'>
        <Navbar />
      </div>
      <div className='w-full'>
        <img src={Desk} alt='desk' className='w-3/4 md:w-1/2 h-auto object-contain mx-auto mt-10 lg:mr-0' />
        <div className='flex flex-col lg:flex-row-reverse'>
          <div className='bg-[rgb(250,249,247)] h-[20rem] w-full lg:w-[58%] mt-6 flex flex-col gap-10 lg:gap-5 justify-center items-center'>
            <h1 className='text-center'>CONSULTANCY</h1>
            <p className='text-center w-[70%]'>
              Our professionals are always available to help your school or institution solve any challenges you are having in managing your library system. Kindly fill in the form below so that we can assist you in overcoming those challenges.
            </p>
          </div>
          <div className='lg:relative left-0 right-0 flex items-center justify-center mx-auto mt-8 lg:-mt-[17rem] lg:-mr-[5rem]'>
            <ConsultancyForm />
          </div>
        </div>
      </div>
      <footer className=''>
          <Footer />
      </footer>
    </div>
  )
}

export default Consultancy