import React, {useState}from 'react';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const [activeOption, setActiveOption] = useState('affiliate');

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

  return (
    <div className='flex flex-col w-full px-6 md:px-16 h-auto bg-white rounded-lg p-5'>
        <div className='bg-[#E0E0E0] w-auto rounded-lg h-auto mx-auto flex flex-row gap-2 p-1 mb-10'>
            <div
                className={`${
                activeOption === 'affiliate' ? 'bg-white' : ''
                } cursor-pointer rounded-lg p-2`}
                onClick={() => handleOptionClick('affiliate')}
            >
                <p className={`${activeOption === 'affiliate' ? 'text-[#5465FF]' : ''}`}>Affiliate</p>
            </div>
            <div
                className={`${
                activeOption === 'admin' ? 'bg-white' : ''
                } cursor-pointer rounded-lg p-2`}
                onClick={() => handleOptionClick('admin')}
            >
                <p className={`${activeOption === 'admin' ? 'text-[#5465FF]' : ''}`}>Admin</p>
            </div>
        </div>
        <h2 className='font-bold text-sm md:text-base mb-3 mx-auto'>Welcome Back to Thrive Library</h2>
        <button className='bg-white border border-gray-300 rounded-lg p-2 mb-5 cursor-pointer'>
            <p className='text-[#E0E0E0] text-center'>Login with Google</p>
        </button>
        <h2 className='font-bold text-base text-[#E0E0E0] mb-3 text-center'>Login With</h2>
        <form onSubmit={handleSubmit} className='flex flex-col'>
            <div className='mb-3 text-[#A7A098] font-sans text-base'>
                <h2>Email</h2>
                <input
                placeholder='comfortthomas@gmail.com'
                required={true}
                className='p-2 rounded-lg text-[#A7A098] w-full bg-[#FAF9F7]'
                value={email}
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <h2 className='text-[#A7A098] font-sans text-base'>Password</h2>
                <input
                placeholder='********'
                required={true}
                className='p-2 rounded-lg text-[#A7A098] w-full mb-6 bg-[#FAF9F7]'
                value={password}
                type='password'
                onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button type='submit' className='bg-[#5465FF] p-3 rounded-lg w-full' >
                <div className='flex items-center justify-center'>
                    <p className='text-white font-sans text-base'>Login</p>
                </div>
          </button>
        </form>
    </div>
  )
}

export default LoginForm