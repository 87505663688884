import React from 'react'
import Logo from '../assets/Thrive Library Logo.png';
import Twitter from '../assets/twitter.png';
import Youtube from '../assets/youtube.png';
import Facebook from '../assets/facebook (2) 1.png';
import Instagram from '../assets/instagram (1).png';

const Footer = () => {
  return (
    <div className='px-6 lg:px-16 border-gray-300 flex justify-between items-center h-64 mx-auto'>
        <div className='flex flex-col md:flex-row items-center justify-start md:justify-center mt-10 w-full'>
            <div className='flex flex-row justify-start md:justify-start md:w-1/3'>
                <img src={Logo} alt='Logo' className='w-32 md:h-2/5 mb-6' />
            </div>
            <div className='flex flex-row justify-start md:justify-center gap-8 md:w-2/3'>
                <div className='flex flex-col w-auto text-center md:text-left'>
                    <h2 className='font-bold mb-2 text-base md:text-lg'>Company</h2>
                    <ul className='flex flex-col space-y-1'>
                        <li className='font-sans cursor-pointer text-sm md:text-base'>
                            <a href='/'>Home</a>
                        </li>
                        <li className='font-sans cursor-pointer text-sm md:text-base'>
                            <a href='/about'>About Us</a>
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col w-auto text-center md:text-left'>
                    <h2 className='font-bold mb-2 text-base md:text-lg'>Services</h2>
                    <ul className='flex flex-col space-y-1'>
                        <li className='font-sans cursor-pointer text-sm md:text-base'>
                            <a href='/consultancy'>Consultancy</a>
                        </li>
                        <li className='font-sans cursor-pointer text-sm md:text-base'>
                            <a href='/affiliate'>Become an Affiliate</a>
                        </li>
                    </ul>
                </div>
                <div className='flex flex-col w-auto text-center md:text-left'>
                    <h2 className='font-bold mb-2 text-base md:text-lg'>Follow Us</h2>
                    <ul className='flex flex-row items-center space-x-1'>
                        <li className='font-sans cursor-pointer'>
                            <a href='#'><img src={Facebook} className='w-4/5' alt='Facebook' /></a>
                        </li>
                        <li className='font-sans cursor-pointer'>
                            <a href='#'><img src={Instagram} className='w-4/5' alt='Instagram' /></a>
                        </li>
                        <li className='font-sans cursor-pointer'>
                            <a href='#'><img src={Twitter} className='w-4/5' alt='Twitter' /></a>
                        </li>
                        <li className='font-sans cursor-pointer'>
                            <a href='#'><img src={Youtube} className='w-4/5' alt='Youtube' /></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-6 md:mt-32 text-sm flex-grow text-center'>
                <p>@2023 Thrive Library Inc. All rights reserved.</p>
            </div>
        </div>
    </div>
  )
}

export default Footer