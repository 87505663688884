import React, {useState} from 'react'
import Navbar from './Navbar'
import Desk from '../assets/Group 555.png';
import Team1 from '../assets/damilare.png';
import Team2 from '../assets/Chris.png';
import Team3 from '../assets/segun.png';
import Team4 from '../assets/kevin.png';
import Students from '../assets/Group 569.png';
import Class from '../assets/Group 556.png';
import {IoMdSend} from 'react-icons/io';
import Footer from './Footer';
import DemoForm from './DemoForm';
import {BiArrowBack} from 'react-icons/bi';

const About = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const [showDemoForm, setShowDemoForm] = useState(false);
    const toggleDemoForm = () => {
        setShowDemoForm(!showDemoForm);
    };

  return (
    <div>
        <div className='z-[1000] relative'>
            <Navbar />
        </div>
        <div className='flex flex-col items-center'>
            <p className='mt-8 text-lg font-bold md:text-xl'>Who we are</p>
            <h1 className='mt-5 text-center text-[#5465FF] font-sans font-roboto-slab w-[80%] font-bold lg:text-4xl md:text-4xl sm:text-3xl text-2xl'>
                Thrive Library is Africa’s growing library management systems 
            </h1>
            <p className='mt-5 w-[80%] md:w-[70%] text-center text-sm md:text-base'>Thrive Library is a library-tech firm that specializes in library set-up, management and maintenance.  We do this by providing consultancy services, tech infrastructure and services that power libraries and make them viable.Who we are</p>
            <img src={Students} alt='students' className='h-auto w-3/4 md:w-3/5 mx-auto relative mt-10' />
        </div>
        <div className='relative mt-10 bg-[rgb(250,249,247)] mx-auto max-w-[90%] p-6'>
            <p className='text-lg md:text-xl text-center lg:text-left lg:ml-[6.5rem] mt-8'>OUR HIGHLIGHT</p>
            <div className='flex flex-col lg:flex-row mt-5 gap-5 lg:gap-10 items-center justify-center'>
                <p className='w-full lg:w-[40%] text-center lg:text-left mt-5 lg:mt-0'>
                We provide library management systems and consultancy services for schools or institutions that want to improve their library management system.
                </p>
                <img src={Desk} alt='desk' className='h-auto w-3/4 md:w-2/5 mx-auto lg:mx-0 object-contain' />
            </div>
            <div className='flex flex-col lg:flex-row-reverse mt-10 lg:mt-5 gap-5 lg:gap-20 items-center justify-center'>
                <p className='w-full lg:w-[40%] text-center lg:text-left mt-5 lg:mt-0'>
                Our Library Management System (LBMS) is designed to make it easy for K-12 and tertiary institutions to manage the operation and delivery of its library services.
                </p>
                <img src={Class} alt='class' className='h-auto w-3/4 md:w-2/5 mx-auto lg:mx-0 object-contain' />
            </div>
        </div>
        <p className='text-xl text-center lg:text-left lg:ml-[6.5rem] relative mt-14 mb-6'>MEET OUR TEAM</p>
        <div class="flex justify-center items-center mb-6">
            <div class="grid md:flex md:flex-row sm:grid-cols-2 grid-cols-1 gap-4 items-center">
                <div class="flex flex-col items-center justify-center p-4">
                    <img src={Team1} alt="Image 1" class="w-40 h-auto mb-2" />
                    <p class="text-base font-bold">Damilare Oyedele</p>
                </div>
                <div class="flex flex-col items-center justify-center p-4">
                    <img src={Team2} alt="Image 2" class="w-36 h-auto mb-2" />
                    <p class="text-base font-bold">Chris Eliezer</p>
                </div>
                <div class="flex flex-col items-center justify-center p-4">
                    <img src={Team3} alt="Image 3" class="w-36 h-auto mb-2" />
                    <p class="text-base font-bold">Segun Aletogbe</p>
                </div>
                <div class="flex flex-col items-center justify-center p-4">
                    <img src={Team4} alt="Image 4" class="w-36 h-auto mb-2" />
                    <p class="text-base font-bold">Kevin Onyancha</p>
                </div>
            </div>
        </div>
        <div className='relative mt-5 bg-[#001219] w-full h-auto p-10 flex flex-col md:flex-row gap-[5rem] justify-center items-center'>
            <div className='bg-[#5465FF] h-[15rem] p-5 w-[20rem] rounded-lg flex flex-col justify-center items-center'>
                <h1 className='mx-auto text-center text-white text-sm'>GET EXPERIENCE</h1>
                <p className='mx-auto text-center text-white text-2xl font-bold mt-2'>Let us show you how ThriveLibrary works.</p>
                <button className='bg-[rgb(250,249,247)] p-3 rounded-lg md:w-44 w-36 mx-auto mt-5' onClick={toggleDemoForm}>
                    <p className='font-sans text-sm md:text-base text-[#5465FF]'>Request for Demo</p>
                </button>
            </div>
            <div className='bg-[#303030] h-[15rem] p-5 w-[20rem] rounded-lg flex flex-col justify-center items-center'>
                <h1 className='mx-auto text-center text-white text-sm'>STAY IN TOUCH</h1>
                <p className='mx-auto text-center text-white text-2xl font-bold mt-2'>Get updates directly into your mailbox</p>
                <form onSubmit={handleSubmit} className='flex flex-row mx-auto items-center mt-5'>
                    <input
                        className='p-2 rounded-lg text-[#A7A098]'
                        placeholder='comfortthomas@gmail.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type='submit' className='ml-2 cursor-pointer text-[#5465FF]'>
                        <IoMdSend />
                    </button>
                </form>
            </div>
        </div>
        <footer>
          <Footer />
      </footer>
      {showDemoForm && (
            <div className='fixed top-0 right-0 bottom-0 left-0 z-[100]'>
                <div className='hidden md:flex bg-black opacity-50 fixed top-0 right-0 bottom-0 left-0' onClick={toggleDemoForm}></div>
                <div className={`bg-[#FAF9F7] px-6 lg:w-[40%] md:w-[60%] w-full h-full transition-transform duration-500 ease-in-out ${showDemoForm ? 'transform translate-x-0' : 'transform -translate-x-full'} fixed top-0 right-0 z-[101]`}>
                    <div className='flex items-center justify-between px-2 md:px-4 py-2'>
                        <button className='text-[#5465FF] flex flex-row items-center' onClick={toggleDemoForm}>
                            <span><BiArrowBack /></span>
                            Back
                        </button>
                        <h1 className='text-base w-full md:text-lg font-semibold text-center flex-grow text-black'>Request For Demo Form</h1>
                    </div>
                    <div className='h-full p-4'>
                        <DemoForm />
                    </div>
                </div>
            </div>
        )}  
    </div>
  )
}

export default About