import React, { useState } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import HomeImage from '../assets/homePageImg.png';
import Books from '../assets/home-1.jpg';
import Students from '../assets/home-2.jpg';
import Money from '../assets/home-3.jpg';
import Request from '../assets/Request.png';
import Service from '../assets/service.png';
import Onboard from '../assets/Onboard.png';
import Audio from '../assets/audio.png';
import Footer from './Footer';
import Arrow from '../assets/—Pngtree—curly arrow_5987458 1.png';
import Paid from '../assets/paid1.png';
import Refer from '../assets/refer1.png';
import Assignment from '../assets/Assignment.png';
import Set from '../assets/set.png';
import Send from '../assets/send.png';
import Message from '../assets/message.png';
import People from '../assets/people.png';
import DemoForm from './DemoForm';
import {BiArrowBack} from 'react-icons/bi';

const Home = () => {
    const [showDemoForm, setShowDemoForm] = useState(false);
    const toggleDemoForm = () => {
        setShowDemoForm(!showDemoForm);
    };

  return (
    <div>
        <Navbar />
        <div className='flex flex-col md:flex-row-reverse items-center gap-10 mb-10 lg:px-16 px-10'>
            <img 
                className="w-1/2 h-auto object-contain md:pt-0 pt-10" 
                src={HomeImage} 
                alt='homeImage' 
            />
            <div className='max-w-[90vw] md:max-w-[100vw] text-center md:text-left'>
                <h1 className='text-[#5465FF] font-sans font-roboto-slab md:ml-10 md:pt-10 pt-2 mb-6 font-bold lg:text-3xl md:text-2xl text-xl md:text-left text-center'>
                    Library Set-Up, Management, and Maintenance
                </h1>
                <p className='font-sans text-base inline-block md:ml-10 mb-6 text-center md:text-left'>
                    We specialize in providing comprehensive solutions for library system management, with a focus on promoting a reading culture. Our services encompass all aspects of library setup, management, and maintenance, ensuring an optimal environment for learning and exploration.
                </p>
                <button className='bg-[#5465FF] rounded-lg p-3 md:w-44 w-36 mt-3 md:ml-10 mb-6 md:mx-auto mx-auto' onClick={toggleDemoForm}>
                    <p className='font-sans text-sm md:text-base text-white'>Request for Demo</p>
                </button>
            </div>
        </div>
        <div className='bg-transparent sm:bg-[rgb(250,249,247)] lg:p-8 md:p-8 p-0 py-8 w-10/12 md:w-11/12 sm:w-9/12 h-auto mx-auto'>
            <h1 className='font-sans lg:text-base text-sm lg:ml-8 md:ml-1 lg:mt-5 ml-12 sm:ml-[3.5rem] py-4'>At Thrive Library</h1>
            <div className='flex flex-col lg:flex-row md:flex-row text-center lg:text-left md:text-left'>
                <img className='w-full sm:w-3/4 md:w-1/2 py-3 lg:py-6 lg:mt-4 lg:h-[22rem] h-3/4 lg:ml-8 mx-auto' src={Books} alt='Books'/>
                <div className='bg-[#5465FF] p-5 w-full sm:w-3/4 md:w-1/2 h-3/4 rounded-lg mx-auto object-contain md:mt-[5%] md:relative md:right-2 lg:relative lg:right-10'>
                    <h1 className='mx-auto text-white py-2 md:py-0 font-bold text-lg lg:text-2xl'>We set up a system that helps manage your library effectively.</h1>
                    <p className='mx-auto text-white py-2 text-sm lg:text-base'>We understand the importance of a well-organized and efficiently managed library system in fostering a love for reading among users. Our team of experts is dedicated to working closely with libraries to create an engaging and user-friendly setup that encourages active participation and knowledge acquisition.
                    </p>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row-reverse md:flex-row-reverse mt-10 lg:mt-0 text-center md:text-left'>
                <img className='w-full sm:w-3/4 md:w-1/2 py-3 lg:py-6 lg:mt-4 lg:h-[22rem] h-3/4 lg:mr-8 mx-auto' src={Students} alt='Students'/>
                <div className='bg-[rgb(250,249,247)] sm:bg-white md:p-2 lg:p-5 p-5 w-full sm:w-3/4 md:w-1/2 h-3/4 lg:h-60 rounded-lg mx-auto object-contain md:mt-[5%] md:relative md:left-5 lg:relative lg:left-10'>
                    <h1 className='mx-auto text-[#5465FF] py-1 md:py-0 font-bold text-lg lg:text-2xl'>We provide tech services and infrastructure that powers libraries to operate and provide services electronically.</h1>
                    <p className='mx-auto text-[#5465FF] py-2 text-sm lg:text-base'>Our easy to use online platform offers a unique experience for library users to engage with their libraries and access library services with ease. 
                    </p>
                </div>
            </div>
            <div className='flex flex-col md:flex-row mt-10 lg:mt-0 text-center md:text-left'>
                <img className='w-full md:w-1/2 sm:w-3/4 py-3 lg:py-6 lg:mt-4 lg:h-[22rem] h-3/4 lg:ml-8 mx-auto md:order-1' src={Money} alt='Money' />
                <div className='bg-[#5465FF] md:p-3 lg:p-5 p-5 w-full sm:w-3/4 md:w-1/2 h-3/4 rounded-lg mx-auto object-contain md:mt-[5%] md:relative md:right-4 lg:relative lg:right-10 md:order-2'>
                    <h1 className='mx-auto text-white py-2 font-bold text-lg lg:text-xl'>When you sign up to become an affiliate, if qualified, you would be trained on your functionalities and be paid a commission for each library successfully onboard.</h1>
                    <p className='mx-auto text-white py-1 text-sm lg:text-base'>When you sign up to become an affiliate, if qualified, you would be trained on your functionalities and be paid a commission for each library successfully onboard.</p>
                </div>
            </div>
        </div>
        <p className='lg:text-lg text-base md:px-16 mt-8 mb-2 flex justify-center lg:justify-start'>
            HOW IT WORKS
        </p>
        <h1 className='lg:text-3xl md:text-2xl text-xl md:px-16 flex justify-center lg:justify-start font-bold w-full mb-2 '>For Library Management</h1>
        <div className="flex flex-col md:flex-row items-center justify-center mb-6">
            <div className="md:flex gap-6 max-w-[80%] md:max-w-[90%] sm:max-w-[60%]">
                <div className="border border-gray-300 p-4 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Request} alt='request' className='mx-auto w-3/4 h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-2xl mt-2'>Request</h1>
                    <p className='font-sans mt-2 text-center text-sm md:text-base'>A school/institution request for its library to be onboard for better management.</p>
                </div>
                <div className="border border-gray-300 p-5 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Onboard} alt='request' className='mx-auto w-[70%] h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-2xl mt-2'>Onboard</h1>
                    <p className='font-sans mt-2 text-center text-sm md:text-base'>A ThriveLibrary onboard the schools/institution and train its library administrators.</p>
                </div>
                <div className="border border-gray-300 p-5 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Service} alt='request' className='mx-auto w-3/4 h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-2xl mt-2'>Service</h1>
                    <p className='font-sans mt-2 text-sm md:text-base text-center'>ThriveLibrary would provide all the necessary facilities to manage your library.</p>
                </div>
            </div>
        </div>
        <h1 className='lg:text-3xl md:text-2xl text-xl md:px-16 flex justify-center lg:justify-start font-bold mb-2'>For Earning</h1>
        <div className="flex flex-col md:flex-row items-center justify-center mb-10 ">
            <div className="md:flex gap-6 max-w-[80%] md:max-w-[90%] sm:max-w-[60%]">
                <div className="border border-gray-300 p-5 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Request} alt='request' className='mx-auto w-3/4 h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-2xl mt-2'>Request</h1>
                    <p className='font-sans mt-2 text-sm md:text-base text-center'>Fill the become an affiliate form, after which you would be contacted for training, if approved. </p>
                </div>
                <div className="border border-gray-300 p-5 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Refer} alt='request' className='mx-auto w-[70%] h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-2xl mt-2'>Refer</h1>
                    <p className='font-sans mt-2 text-sm md:text-base text-center'>After onboarding you would reach out to libraries that needs ThriveLibrary services and onboard them.</p>
                </div>
                <div className="border border-gray-300 p-5 h-auto md:w-1/2 w-3/4 mx-auto mt-5 rounded-lg">
                    <img src={Paid} alt='request' className='mx-auto w-3/4 h-auto object-contain' />
                    <h1 className='text-[#5465FF] font-sans text-center font-bold text-xl md:text-2xl mt-2'>Get Paid</h1>
                    <p className='font-sans mt-2 text-sm md:text-base text-center'>On each successful onboarding, you get paid a commission for your good work.</p>
                </div>
            </div>
        </div>
        <div className='bg-[#5465FF] p-8 w-[85%] h-auto mx-auto rounded-lg flex flex-col md:flex-row items-center'>
            <h1 className='text-white font-sans font-roboto-slab p-4 w-full md:w-[60%] font-bold lg:text-3xl md:text-2xl sm:text-xl text-lg text-center md:text-left'>
                To be an affiliate and start earning on ThriveLibrary, click this button.
            </h1>
            <img className='hidden lg:flex lg:absolute lg:w-[30%] lg:h-auto lg:ml-[35%] lg:mt-[6rem]' alt='' src={Arrow} />
            <Link to="/affiliate" className='bg-[rgb(250,249,247)] p-3 rounded-lg md:w-44 w-36 md:ml-[10%] cursor-pointer'>
                <span className='font-sans text-sm md:text-base text-[#5465FF] flex items-center justify-center'>
                    Become an Affiliate
                </span>
            </Link>
        </div>
        <div className='bg-[rgb(250,249,247)] lg:mt-[10%] md:p-10 p-6 md:w-11/12 sm:w-9/12 h-auto mx-auto'>
            <h1 className='text-center md:text-left md:ml-5 font-sans text-base md:text-lg'>BENEFITS OF USING OUR PLATFORM</h1>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-5 ml-6 mt-7'>
                <div>
                    <img src={Message} alt='message' className='w-[30%] lg:w-[15%] h-auto object-contain'/>
                    <p className='font-sans text-sm md:text-base'>Student never get bored of reading with our classmate reading club</p>
                </div>
                <div>
                    <img src={Audio} alt='audio' className='w-[30%] lg:w-[15%] h-auto object-contain'/>
                    <p className='font-sans text-sm md:text-base'>Audio book reader help student with reading challenge to read better</p>
                </div>
                <div>
                    <img src={Send} alt='upload'className='w-[20%] lg:w-[10%] h-auto object-contain' />
                    <p className='font-sans text-sm md:text-base'>Student can upload their own books, thereby creating personal library</p>
                </div>
                <div>
                    <img src={People} alt='staff'  className='w-[20%] lg:w-[10%] h-auto object-contain'/>
                    <p className='font-sans text-sm md:text-base'>Staff monitor student reading habit to provide counseling where necessary</p>
                </div>
                <div>
                    <img src={Assignment} alt='assignment' className='w-[20%] lg:w-[10%] h-auto object-contain'/>
                    <p className='font-sans text-sm md:text-base'>Staff can easily share books and other learning resources with their students</p>
                </div>
                <div>
                    <img src={Set} alt='manage' className='w-[20%] lg:w-[10%] h-auto object-contain'/>
                    <p className='font-sans text-sm md:text-base'>Ease manage of library system by School or Institution library admin</p>
                </div>
            </div>
        </div>
        <div className='bg-[#5465FF] mt-0 md:p-10 p-6 w-full h-auto flex flex-col justify-center items-center'>
            <div>
                <h1 className='text-center font-bold md:text-2xl sm:text-xl text-lg text-white w-full md:w-[70%] mx-auto'>
                    Let's show you a demonstration of how helpful our platform is. Click the button below
                </h1>
                <div className='flex items-center justify-center mt-6 md:mt-8'>
                    <button className='bg-[rgb(250,249,247)] p-3 rounded-lg md:w-44 w-36 mx-auto' onClick={toggleDemoForm}>
                        <p className='font-sans text-sm md:text-base text-[#5465FF]'>Request for Demo</p>
                    </button>
                </div>
            </div>
        </div>
        <footer>
            <Footer />
        </footer>
        {showDemoForm && (
            <div className='fixed top-0 right-0 bottom-0 left-0 z-[100]'>
                <div className='hidden md:flex bg-black opacity-50 fixed top-0 right-0 bottom-0 left-0' onClick={toggleDemoForm}></div>
                <div className={`bg-[#FAF9F7] px-5 lg:w-[40%] md:w-[60%] w-full h-full transition-transform duration-500 ease-in-out ${showDemoForm ? 'transform translate-x-0' : 'transform -translate-x-full'} fixed top-0 right-0 z-[101]`}>
                    <div className='flex items-center justify-between px-2 md:px-4 py-2'>
                        <button className='text-[#5465FF] flex flex-row items-center' onClick={toggleDemoForm}>
                        <span><BiArrowBack /></span>
                            Back
                        </button>
                        <h1 className='text-base w-full md:text-lg font-semibold text-center flex-grow text-black'>Request For Demo Form</h1>
                    </div>
                    <div className='h-full p-4'>
                        <DemoForm />
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Home;