import React, {useState}from 'react';
import {BiMessageDetail} from 'react-icons/bi';

const AffiliateForm = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [profession, setProfession] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    };


    return (
        <div className='bg-[#2835AD] p-3 md:p-4 mx-4 h-auto w-[90%] rounded-lg'>
            <div className='mx-4'>
                <h1 className='text-white font-sans font-bold text-xl'>Become an Affiliate Form</h1>
                <p className='text-white font-sans text-base mt-2'>Kindly fill in the form below if you want to become an affiliate.</p>
            </div>
            <form onSubmit={handleSubmit} className='flex flex-col gap-4 mt-6'>
                <div className='flex flex-col sm:flex-row md:gap-6 gap-4 mx-4'>
                    <div>
                        <h2 className='text-white font-sans text-base'>Full Name</h2>
                            <input
                            placeholder='Comfort Thomas'
                            required={true}
                            className='p-2 rounded-lg text-[#A7A098] w-full'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                    </div>
                    <div>
                        <h2 className='text-white font-sans text-base'>Phone Number</h2>
                        <input
                        placeholder='08000000000'
                        required={true}
                        className='p-2 rounded-lg text-[#A7A098] w-full'
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        />
                    </div>
                </div>
                <div className='mx-4'>
                    <h2 className='text-white font-sans text-base'>Email</h2>
                    <input
                        placeholder='ctuniversity@gmail.com'
                        required={true}
                        className='p-2 rounded-lg text-[#A7A098] w-full'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='flex flex-col sm:flex-row md:gap-6 gap-4 mx-4'>
                    <div>
                        <h2 className='text-white font-sans text-base'>Country</h2>
                        <input
                        placeholder='Nigeria'
                        required={true}
                        className='p-2 rounded-lg text-[#A7A098] w-full'
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                    <div>
                        <h2 className='text-white font-sans text-base'>Profession</h2>
                        <input
                        placeholder='Librarian'
                        required={true}
                        className='p-2 rounded-lg text-[#A7A098] w-full'
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        />
                    </div>
                </div>
                <div className='mx-4'>
                    <h2 className='text-white font-sans text-base'>Linkedin Profile Link</h2>
                    <input
                        placeholder='linkedin.com/in/comfort-thomas-a123456'
                        required={true}
                        className='p-2 rounded-lg text-[#A7A098] w-full'
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                </div>
                <div className='mx-4'>
                    <h2 className='text-white font-sans text-base'>Additional Information</h2>
                    <textarea
                        placeholder='Kindly provide any additional information you want us to know'
                        required={false}
                        className='p-3 rounded-lg text-[#A7A098] w-full h-36'
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                </div>
                <div className='mx-4'>
                <button type='submit' className='bg-[#5465FF] p-3 rounded-lg w-full'>
                    <div className='flex items-center justify-center'>
                        <span className='text-white text-xl mt-[0.15rem]'><BiMessageDetail /></span>
                        <p className='text-white font-sans text-base'>Send Request</p>
                    </div>
                </button>
                </div>
            </form>
        </div>
    )
}

export default AffiliateForm