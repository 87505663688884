import React from "react";
import Home from "./components/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Consultancy from "./components/Consultancy";
import Affiliate from './components/Affiliate';
import About from "./components/About";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/consultancy' element={<Consultancy />}></Route>
          <Route path='/affiliate' element={<Affiliate />}></Route>
          <Route path='/about' element={<About />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
