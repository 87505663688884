import React from 'react'
import Navbar from './Navbar'
import Desk from '../assets/Group 555.png';
import AffiliateForm from './affiliateForm';
import Benefits from '../assets/affiliate benefits.png';
import Footer from './Footer';

const Affiliate = () => {
  return (
    <div className='flex flex-col'>
      <div className='z-[1000] relative'>
        <Navbar />
      </div>
      <div className='w-full'>
        <img src={Desk} alt='desk' className='w-3/4 md:w-1/2 h-auto object-contain mx-auto mt-10 lg:mr-0' />
        <div className='flex flex-col lg:flex-row-reverse'>
          <div className='bg-[rgb(250,249,247)] h-auto w-full lg:w-[58%] mt-6 flex flex-col gap-10 lg:gap-5 justify-center items-center'>
            <h1 className='text-center mt-6'>BECOME AN AFFILIATE</h1>
            <p className='text-center md:w-[70%] w-[85%]'>
              By becoming an affiliate with us, you can play a vital role in expanding the reach of Thrive Library. As an affiliate, your responsibility will involve sourcing and onboarding schools or institutions to join our platform. For every successful onboarding, you will earn a commission that will be paid to you. If you are interested in becoming our affiliate, we invite you to apply by filling out the form provided
            </p>
            <img src={Benefits} alt='benefits' className='w-3/4 md:w-1/2 h-auto object-contain mx-auto' />
          </div>
          <div className='lg:relative left-0 right-0 flex items-center justify-center mx-auto mt-8 lg:-mt-[17rem] lg:-mr-[5rem]'>
            <AffiliateForm />
          </div>
        </div>
      </div>
      <footer className=''>
        <Footer />
      </footer>
    </div>
  )
}

export default Affiliate