import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/Thrive Library Logo.png';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import LoginForm from './loginForm';
import DemoForm from './DemoForm';
import {BiArrowBack} from 'react-icons/bi';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav)
    }

    const [showForm, setShowForm] = useState(false);
    const [showDemoForm, setShowDemoForm] = useState(false);

    const toggleForm = () => {
        setShowForm(!showForm);
    };
    const toggleDemoForm = () => {
        setShowDemoForm(!showDemoForm);
    };
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location]);

  return (
    <div className='lg:px-20 md:px-1 px-6 border-b border-gray-300 flex justify-between items-center h-24 mx-auto'>
        <div className='flex flex-row items-center justify-between w-full'>
            <a href='/'><img className="w-32" src={Logo} alt='Logo' /></a>
            <div className='flex items-center'>
                <ul className='hidden md:flex justify-center space-x-4'>
                    <li
                        className={`font-bold lg:text-base text-sm font-sans cursor-pointer ${
                        activePath === '/' ? 'text-[#5465FF]' : ''
                        }`}
                    >
                        <a href='/'>Home</a>
                    </li>
                    <li
                        className={`font-bold lg:text-base text-sm font-sans cursor-pointer ${
                        activePath === '/consultancy' ? 'text-[#5465FF]' : ''
                        }`}
                    >
                        <a href='/consultancy'>Consultancy</a>
                    </li>
                    <li
                        className={`font-bold lg:text-base text-sm font-sans cursor-pointer ${
                        activePath === '/about' ? 'text-[#5465FF]' : ''
                        }`}
                    >
                        <a href='/about'>About Us</a>
                    </li>
                    <li
                        className={`font-bold lg:text-base text-sm font-sans cursor-pointer ${
                        activePath === '/affiliate' ? 'text-[#5465FF]' : ''
                        }`}
                    >
                        <a href='/affiliate'>Become an Affiliate</a>
                    </li>
                </ul>
            </div>
            <div className='hidden md:flex items-center space-x-3'>
                <div>
                    <button className='bg-[#F7F8FA] border border-[#5465FF] rounded-lg p-3 w-[80%] md:w-[5rem]' onClick={toggleForm}>
                        <p className='font-sans text-sm lg:text-base text-[#5465FF]'>Login</p>
                    </button>
                </div>
                <div>
                    <button className='bg-[#5465FF] rounded-lg p-3 w-[80%] md:w-[10rem]' onClick={toggleDemoForm}>
                        <p className='font-sans text-sm lg:text-base text-white'>Request for Demo</p>
                    </button>
                </div>
            </div>
        </div>
        <div onClick={handleNav} className='block md:hidden'>
            {!nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
        </div>
        <div className={`fixed top-0 left-0 w-[70%] h-screen bg-white transition-transform duration-500 ease-in-out ${!nav ? 'transform translate-x-0' : 'transform -translate-x-full'} md:hidden`}>
            <div className='flex flex-col h-full'>
                <a href='/'><img className="w-32 m-5" src={Logo} alt='Logo' /></a>
                <ul className='p-4 uppercase'>
                    <li className='p-4 font-bold font-sans border-b border-gray-300 cursor-pointer'>
                        <a href='/'>Home</a>
                    </li>
                    <li className='p-4 font-bold font-sans border-b border-gray-300 cursor-pointer'>
                        <a href='/consultancy'>Consultancy</a>
                    </li>
                    <li className='p-4 font-bold font-sans border-b border-gray-300 cursor-pointer'>
                        <a href='/about'>About Us</a>
                    </li>
                    <li className='p-4 font-bold font-sans cursor-pointer'>
                        <a href='/affiliate'>Become an Affiliate</a>
                    </li>
                </ul>
                <button className='bg-[#F7F8FA] border border-[#5465FF] rounded-lg p-3 w-[80%] mt-0 mb-3 mx-auto' onClick={toggleForm}>
                    <p className='font-sans text-sm md:text-base text-[#5465FF]'>Login</p>
                </button>
                <button className='bg-[#5465FF] rounded-lg p-3 w-[80%] mx-auto' onClick={toggleDemoForm}>
                    <p className='font-sans text-sm md:text-base text-white'>Request for Demo</p>
                </button>
            </div>
        </div>
        {showDemoForm && (
            <div className='fixed top-0 right-0 bottom-0 left-0 z-[100]'>
                <div className='hidden md:flex bg-black opacity-50 fixed top-0 right-0 bottom-0 left-0' onClick={toggleDemoForm}></div>
                <div className={`bg-[#FAF9F7] px-5 lg:w-[40%] md:w-[60%] w-full h-full transition-transform duration-500 ease-in-out ${showDemoForm ? 'transform translate-x-0' : 'transform -translate-x-full'} fixed top-0 right-0 z-[101]`}>
                    <div className='flex items-center justify-between px-2 md:px-4 py-2'>
                        <button className='text-[#5465FF] flex flex-row items-center' onClick={toggleDemoForm}>
                        <span><BiArrowBack /></span>
                            Back
                        </button>
                        <h1 className='text-base w-full md:text-lg font-semibold text-center flex-grow text-black'>Request For Demo Form</h1>
                    </div>
                    <div className='h-full p-4'>
                        <DemoForm />
                    </div>
                </div>
            </div>
        )}
        {showForm && (
            <div className='fixed top-0 right-0 bottom-0 left-0 z-[100]'>
                <div className='bg-black opacity-50 fixed top-0 right-0 bottom-0 left-0' onClick={toggleDemoForm}></div>
                <div className={`bg-[#FAF9F7] px-5 lg:w-[40%] md:w-[60%] w-full h-full transition-transform duration-500 ease-in-out ${showForm ? 'transform translate-x-0' : 'transform -translate-x-full'} fixed top-0 right-0 z-[101]`}>
                    <div className='flex items-center justify-between px-2 md:px-4 py-2'>
                        <button className='text-[#5465FF] flex flex-row items-center' onClick={toggleForm}>
                        <span><BiArrowBack /></span>
                            Back
                        </button>
                        <h1 className='text-base w-full md:text-lg font-semibold text-center flex-grow text-black'>Login to Thrive Library Account</h1>
                    </div>
                    <div className='h-full p-4'>
                        <LoginForm />
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Navbar